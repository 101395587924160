exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-how-we-work-tsx": () => import("./../../../src/pages/how-we-work.tsx" /* webpackChunkName: "component---src-pages-how-we-work-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-developer-tsx": () => import("./../../../src/pages/jobs/developer.tsx" /* webpackChunkName: "component---src-pages-jobs-developer-tsx" */),
  "component---src-pages-jobs-index-tsx": () => import("./../../../src/pages/jobs/index.tsx" /* webpackChunkName: "component---src-pages-jobs-index-tsx" */),
  "component---src-pages-payments-tsx": () => import("./../../../src/pages/payments.tsx" /* webpackChunkName: "component---src-pages-payments-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

