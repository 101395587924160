module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-47663232-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","include_favicon":false,"icons":[{"src":"favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"favicons/favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/favicon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/favicon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"ca5a6bfd5744d17c10ec3aa42bc7422f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
